import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import store from "@/store";
import {ElNotification} from "element-plus";
import AdminRouter from "@/router/adminRouter";
const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=>import("@/views/AppLayout.vue"),
    children:[
      {name:"FormIndex",path:"/",component:()=>import("@/views/form/FormIndex")},
      {name:"FormAudit",path:"/audit",component:()=>import("@/views/form/AuditView")},
      {name:"FormWorte",path:"/worte",component:()=>import("@/views/form/WorteView")},
      {name:"FormWrite",path:"/info",component:()=>import("@/views/form/FormWrite")},
      {name:"ProcessRead",path:"/readProcess/:id",component:()=>import("@/views/form/ProcessRead")},
      {name:"FormList",path:"/read",component:()=>import("@/views/form/FormList")},
      {name:"EditPassword",path:"/password",component:()=>import("@/views/EditPassword.vue")},
        ...AdminRouter
    ]
  },
  {name:"FormRead",path:"/read/:id",component:()=>import("@/views/form/FormRead")},
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/test',
    name: 'test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestTest.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
  const isAdmin = store.getters.isSuper === 1;
  if(to.path.startsWith("/admin") && !isAdmin){
    ElNotification.error({title:"您没有权限访问该页面"});
    next(from);
  }else{
    next();
  }
})
export default router
