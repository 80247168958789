import DataLayout from "@/components/DataLayout.vue";
import FormLayout from "@/components/FormLayout.vue";
import SwitchStatus from "@/components/SwitchStatus.vue";
import UserSelect from "@/components/UserSelect.vue";
import CustomNode from "@/components/CustomNode.vue";
import UserOnlySelect from "@/components/UserOnlySelect.vue";
import TagManager from '@/components/TagManager.vue'
import ChartSetting from '@/components/ChartSetting.vue'
const components = {DataLayout,FormLayout,SwitchStatus,UserSelect,TagManager,UserOnlySelect,CustomNode,ChartSetting};
export default {
    install(Vue){
        Object.keys(components).forEach(key=>{
            Vue.component(key,components[key]);
        })
    }
}
