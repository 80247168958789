<template>
  <div class="chart-component">
    <div class="title">产量</div>
    <div id="c-l"></div>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, watch} from 'vue'
import {getCellValue} from "@/utils";
import {Bar} from "@antv/g2plot";
let ChartObj;
const props = defineProps({
  sheet:{
    default(){
      return null;
    }
  }
})
const rowKey = "D";
const DataTemplate =ref([]);
DataTemplate.value= [
  {title:"丁香",cate:"火腿类",value:0},
  {title:"午餐肉",cate:"火腿类",value:0},
  {title:"三文治",cate:"火腿类",value:0},
  {title:"虎皮红肠",cate:"红肠类",value:0},
  {title:"力佳红肠",cate:"红肠类",value:0},
  {title:"传统红肠",cate:"红肠类",value:0},
  {title:"松花鸡腿",cate:"手工类",value:0},
  {title:"松花汉堡",cate:"手工类",value:0},
  {title:"猪手",cate:"熏酱类",value:0},
  {title:"猪头肉",cate:"熏酱类",value:0},
];
const updateChartData = (sheet)=>{
  //丁香
  DataTemplate.value[0].value = getCellValue(sheet,`${rowKey}4`);
  //午餐肉
  DataTemplate.value[1].value = getCellValue(sheet,rowKey+"5");
  //三文治
  DataTemplate.value[2].value = getCellValue(sheet,rowKey+"6");
  //虎皮红肠
  DataTemplate.value[3].value = getCellValue(sheet,rowKey+"8");
  //力佳红肠
  DataTemplate.value[4].value = getCellValue(sheet,rowKey+"9");
  //传统红肠
  DataTemplate.value[5].value = getCellValue(sheet,rowKey+"10");
  //松花鸡腿
  DataTemplate.value[6].value = getCellValue(sheet,rowKey+"12");
  //松花汉堡
  DataTemplate.value[7].value = getCellValue(sheet,rowKey+"13");
  //猪手
  DataTemplate.value[8].value = getCellValue(sheet,rowKey+"15");
  //猪头肉
  DataTemplate.value[9].value = getCellValue(sheet,rowKey+"16");
  DataTemplate.value.sort(function (x,y){
    return -(x.value - y.value);
  })
  if(ChartObj){
    ChartObj.changeData(DataTemplate.value);
  }
}
watch(()=>props.sheet,(e)=>{
  if(e){
    updateChartData(e);
  }
},{deep:true,immediate:true});

const createChart = ()=>{
  ChartObj  = new Bar("c-l",{
    data:DataTemplate.value,
    height:400,
    xField:"value",
    yField:"title",
    label:{
      content:(e)=>{
        return e.value;
      }
    }
  });
  ChartObj.render();
}
onMounted(createChart);
</script>

<style scoped>

</style>
