<template>
<div class="chart-component">
  <div class="title">抚远受理数据</div>
  <el-form>
    <el-form-item>
      <el-radio-group @change="changeType" v-model="query.type">
        <el-radio-button label="title">全省受理</el-radio-button>
        <el-radio-button label="region">佳木斯地区受理</el-radio-button>
      </el-radio-group>
    </el-form-item>
  </el-form>
  <div id="f-y-s-l"></div>
</div>
</template>
<script setup>
import {ref, defineProps, computed, onMounted, watch} from 'vue'
import {getCellValue} from "@/utils";
import {Pie} from '@antv/g2plot'
import _ from 'lodash'
let chartObj;
const query = ref({
  type:"title"
})
const props = defineProps({
  sheet:{
    default(){
      return null;
    }
  }
});
const DataTemplate = ref([]);
DataTemplate.value = [
  {title:"同江",region:"佳木斯",value:0},
  {title:"富锦",region:"佳木斯",value:0},
  {title:"桦南",region:"佳木斯",value:0},
  {title:"汤原",region:"佳木斯",value:0},
  {title:"桦川",region:"佳木斯",value:0},
  {title:"佳木斯市区",region:"佳木斯",value:0},
  {title:"抚远本地",region:"抚远本地",value:0},
  {title:"省内\n（除佳木斯地区）",region:"省内\n(除佳木斯地区）",value:0},
  {title:"省外",region:"省外",value:""},
];
const ChartData = computed(()=>{
  let JiaMuSi = [];
  let All     = [];
  DataTemplate.value.forEach(item=>{
    if(item.region === '佳木斯')
    {
      JiaMuSi.push(item);
    }
    if(All[item.region]){
      All[item.region].value += item.value;
    }else{
      All[item.region] = _.cloneDeep(item);
    }
    item.val = item.value;
  })
  All = Object.values(All);
  All.sort(function (x,y){
    return -(x.value - y.value);
  })
  JiaMuSi.sort(function (x,y){
    return -(x.value - y.value);
  })
  console.log({All:All,JiaMuSi:JiaMuSi});
  return {All:All,JiaMuSi:JiaMuSi};
});
const createChart = ()=>{
  chartObj = new Pie("f-y-s-l",{
    appendPadding: 50,
    data:ChartData.value.All,
    angleField: 'value',
    colorField: 'region',
    radius: 1,
    legend:false,
    label: {
      type: 'inner',
      labelHeight:30,
      labelWidth:300,
      content: (e) => {
          return `${query.value.type === 'title' ? e.region : e.title}\n${e.value}人(${(e.percent * 100).toFixed(0)}%)`
      },
      style: {
        fill:"#000",
        fontSize: 10,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
  });
  chartObj.render();
}
const changeType = ()=>{
  if(chartObj){
    if(query.value.type === 'title'){
      chartObj.changeData(ChartData.value.All);
      chartObj.update({
        colorField:"region"
      })
    }else if(query.value.type === 'region'){
      chartObj.changeData(ChartData.value.JiaMuSi);
      chartObj.update({
        colorField:"title"
      })
    }
  }
}
onMounted(createChart)

const updateChartData = (sheet)=>{
  DataTemplate.value[0].value = getCellValue(sheet,"A3");
  DataTemplate.value[1].value = getCellValue(sheet,"B3");
  DataTemplate.value[2].value = getCellValue(sheet,"C3");
  DataTemplate.value[3].value = getCellValue(sheet,"D3");
  DataTemplate.value[4].value = getCellValue(sheet,"E3");
  DataTemplate.value[5].value = getCellValue(sheet,"F3");
  DataTemplate.value[6].value = getCellValue(sheet,"D4");
  DataTemplate.value[7].value = getCellValue(sheet,"D4");
  DataTemplate.value[8].value = getCellValue(sheet,"D6");
  changeType();
}
watch(()=>props.sheet,(e)=>{
  updateChartData(e);
},{deep:true,immediate:true});

</script>

<style scoped>

</style>
