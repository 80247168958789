<template>
<div class="data-layout">
  <div class="header">
    <el-form label-width="70px" inline @submit.prevent="onSubmit">
      <slot name="header"></slot>
    </el-form>
  </div>
  <div class="body" ref="bodyDom">
    <slot :data="size"></slot>
  </div>
  <div class="footer" v-if="hideFooter">
    <el-pagination :total="total" :page-size="20" background small @current-change="changePage"></el-pagination>
  </div>
</div>
</template>
<script setup>
import {defineProps, computed, defineEmits, ref, onMounted, nextTick} from 'vue'
const bodyDom = ref(null);
const emit = defineEmits(["onQuery"])
const size = ref({width:0,height:0});
const props = defineProps({
  hideFooter:{
    type:Boolean,
    default(){
      return false
    }
  },
  total:{
    default(){
      return 0;
    }
  }
})
const hideFooter = computed(()=>{
  return (props.hideFooter === false);
})
const  changePage =(p)=>{
  emit("onQuery",p);
}
const onSubmit = ()=>{
  emit("onQuery",1);
}
onMounted(()=>{
  nextTick(()=>updateSize())
})

const updateSize = ()=>{
  size.value.width = bodyDom.value.offsetWidth;
  size.value.height = bodyDom.value.offsetHeight;
}
window.onresize = updateSize;
</script>
<style lang="scss">
.data-layout{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  .header{
    padding: 10px 10px 0 10px;
  }
  .body{
    overflow: scroll;
    height: 100%;
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .footer{
    padding: 10px;
  }
}
</style>
