<template>
<div class="chart-component">
  <div class="title">受理数据</div>
  <el-form>
    <el-form-item>
      <el-radio-group v-model="query.type" @change="changeType">
        <el-radio-button label="school">按驾校</el-radio-button>
        <el-radio-button label="region">按地区</el-radio-button>
      </el-radio-group>
    </el-form-item>
  </el-form>
  <div id="s-l-chart"></div>
</div>
</template>

<script setup>
import {ref, defineProps, onMounted, computed, watch} from 'vue';
import {Bar,Column} from '@antv/g2plot'
import {getCellValue} from "@/utils";
import _ from 'lodash'
let chartObj;
const query = ref({
  type:"school"
});
const props = defineProps({
  sheet:{
    default(){
      return null;
    }
  }
});
const ChartData = ref([]);
ChartData.value =  [
  {region:"市区",school:"自培",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"宏大驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"力佳驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"佳南分校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"顺通驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"佳信驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"新途驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"龙运驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"砺学驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"远通驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"海东驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"信诚驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"鹏程驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"平安驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"教育驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"环通驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"市区",school:"桦川驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"桦南",school:"翔德驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"桦南",school:"博远驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"富锦",school:"万里驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"富锦",school:"富通驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"富锦",school:"丰驰驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"富锦",school:"九龙驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"富锦",school:"鹏阳驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"抚远",school:"抚远驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"同江",school:"同江驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""},
  {region:"汤原",school:"汤原驾校",car:{C1:100,C2:200,C5:150,B1:100,B2:75,A1:141,A2:145,A3:78},value:""}
];

const DataList  = computed(()=>{
  let data = [];
  ChartData.value.forEach(item=>{
    Object.keys(item.car).forEach(key=>{
      let newItem = _.cloneDeep(item);
      newItem.car = key;
      newItem.value = item.car[key];
      data.push(newItem);
    });
  });
  console.log(data);
  return data;
})

const SchoolChartData = computed(()=>{
  let data = [];
  DataList.value.forEach(item=>{
    item.val = item.value;
    data.push(item);
  })
  return data;
})
const RegionChartData = computed(()=>{
  let data = [];
  DataList.value.forEach(item=>{
    var key = item.region + "_"+item.car;
    if(data[key]){
      data[key]['value'] += item.value;
      data[key]['val'] += item.val;
    }else{
      item.val = item.value;
      data[key] =_.cloneDeep(item);
    }
  })
  data.sort(function (x,y){
    return x.value - y.value;
  })
  return Object.values(data);
});
const createChart = ()=>{
  chartObj = new Bar("s-l-chart",{
    height:1200,
    data:SchoolChartData.value,
    xField:"value",
    yField:"school",
    xAxis:{
      label:{
        formatter(e){
          return parseFloat(e*100).toFixed(2)+"%";
        }
      }
    },
    yAxis:{
      label:{
        formatter(e){
          let total = 0;
          let key =query.value.type;
          SchoolChartData.value.forEach(item=>{
            if(item[key] === e){
              total+= item.value;
            }
          })
          return `${e}\n${total}人`
        }
      }
    },
    isStack:true,
    seriesField:"car",
    isPercent:true,
    legend:false,
    tooltip: {
      fields:["school",'val','value','car'],
      formatter: (datum) => {
        return { name: datum.car, value: `${datum.val}人(占比：${parseFloat(datum.value*100).toFixed(2)}%)` };
      },
    }
  })
  chartObj.render();
};
const changeType = ()=>{
  UpdateData()
}
onMounted(createChart);
const UpdateData = ()=>{
  console.log(SchoolChartData.value);
  console.log(RegionChartData.value);
  if(chartObj){
    if(query.value.type === 'school'){
      chartObj.changeSize(chartObj.chart.width,1200)
      chartObj.changeData(SchoolChartData.value);
    }else if(query.value.type === 'region'){
      chartObj.changeSize(chartObj.chart.width,300)
      console.log(RegionChartData.value);
      chartObj.changeData(RegionChartData.value);
    }
    chartObj.update({
      yField:query.value.type,
      //  seriesField:'school'
    });
  }
}

const updateChartData = (sheet)=>{
  let data = _.cloneDeep(ChartData.value);
  data.forEach((item,key)=>{
    let index = key+3;
    item.car.C1 = getCellValue(sheet,`C${index}`);
    item.car.B2 = getCellValue(sheet,`D${index}`);
    item.car.C2 = getCellValue(sheet,`D${index}`);
    item.car.C5 = getCellValue(sheet,`E${index}`);
    item.car.A3 = getCellValue(sheet,`F${index}`);
    item.car.A2 = getCellValue(sheet,`G${index}`);
    item.car.B1 = getCellValue(sheet,`H${index}`);
    item.car.A1 = getCellValue(sheet,`I${index}`);
    item.value = getCellValue(sheet,`C${index}:J${index}`);
  })
  ChartData.value = data;
  data.sort(function (x,y){
    return -(x.value - y.value);
  })
  UpdateData();
}
watch(()=>props.sheet,(e)=>{
  console.log(e);
  updateChartData(e);
},{deep:true,immediate:true});

</script>
