import axios from "axios";
import qs from 'qs';
import {ElLoading, ElNotification} from "element-plus";
import configData from '@/config'
const ajax = axios.create({
    baseURL:"/index.php/",
    withCredentials:true,
});
// 请求拦截器
ajax.interceptors.request.use(config=>{
    if(config.method === 'post'){
        config.data = qs.stringify(config.data);
    }
    return config;
},err => {
    return new Promise.reject(`请求失败，失败原因 :${err.message}`);
});
// 相应拦截器
ajax.interceptors.response.use(config=>{
    if(config.status === 200){
        const response = config.data;
        if(response.code === 201){
            ElNotification.success({message:response.msg});
           router.push({name:"login",query:{callback:document.URL}})
        }else{
            return Promise.resolve(response);
        }
        return Promise.reject(response.message);
    }else{
        return Promise.reject(`服务器繁忙:${config.statusText}`);
    }
},err=>{
    return Promise.reject(`服务器繁忙:${err.message}`);
});
export const get = (url,params = {})=> ajax.get(url,{params:params});
export const post =(url,data={}) => ajax.post(url,data);
export const api = (fn,message="正在加载中...") => {
    return (...args) => {
        const service = ElLoading.service({
            text: message
        });
        return new Promise((resolve, reject) => {
            fn(...args).then(resolve).catch(reject).finally(() => {
                service.close();
            })
        })
    }
}
/* 对数据进行克隆 */
import _, {isNumber} from 'lodash'
import router from "@/router";
export const cloneDeep = (var1,var2)=>{
    Object.keys(var1).forEach(key=>{
        if(var2[key]){
            var1[key] = _.cloneDeep(var2[key]);
        }
    })
    return var1;
}
export const importJs = (key,url)=>{
    return new Promise((resolve)=>{
        let js = document.getElementById(key);
        if(js != null){
            js.remove();
        }
        js = document.createElement("script");
        js.src = url;
        js.onload = ()=>{
            resolve(window[key]);
        }
        document.head.appendChild(js);
    })
}
export const WithNotice = (fn)=>{
    return (...args)=>{
        return new Promise(((resolve, reject) => {
            return fn(...args).then(res=>{
                console.log(res);
                if(res.code === 1){
                    if(res.msg){
                        ElNotification.success({message:res.msg});
                    }
                    resolve(res.data);
                }else{
                    if(res.msg){
                        ElNotification.error({message:res.msg})
                    }
                    reject(res);
                }
            }).catch(err=>{
                ElNotification.error({message:err});
                reject(err);
            })
        }))
    }
}
export const formatDate = (date,format = "yyyy-mm-dd")=>{
    if(typeof date === 'string'){
        date = new Date(date);
    }else if(date === null || date === undefined){
        date = new Date();
    }
    let year =  date.getFullYear();
    let month = date.getMonth()+1
    if(month < 10){
        month = `0${month}`
    }
    let day   = date.getDate();
    if(day < 10){
        day = `0${day}`
    }
    return format.replace("yyyy",year).replace("mm",month).replace("dd",day);
}
import alphabet from "@/modules/x-sheet/src/core/alphabet";
import RangeCell from '@/modules/x-sheet/src/core/cell_range';
export const getCellValue = (sheet,cellKey)=>{
    let total  = 0 ;
    getCellRange(cellKey,(ri,ci)=>{
        let c =  sheet.cell(ci,ri);
        if(c){
            let number = parseFloat(c.text);
            if(!isNaN(number)&& number!== undefined ){
                total += parseFloat(number);
            }
        }
    })
    return total;
}
const getCellRange = (cell,callback)=>{
    if(cell.indexOf(":") > -1){
        var arr = cell.split(":");
        var s   = alphabet.expr2xy(arr[0]);
        var e  =  alphabet.expr2xy(arr[1]);
        (new RangeCell(s[0],s[1],e[0],e[1])).each(callback)
    }else{
        let position = alphabet.expr2xy(cell)
        callback(position[0],position[1])
    }
}
