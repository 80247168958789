<template>
  <el-popover v-model:visible="isShow" width="300px" placement="bottom-start"  trigger="click" >
    <template #reference>
    <div class="user-only-select">
        <div class="value">{{props.selected.name}}</div>
        <div class="selected"></div>
      </div>
    </template>
    <template #default>
      <el-tree :highlight-current="true" :default-expand-all="true" :expand-on-click-node="true" :check-strictly="true" @current-change="changeSelect" :data="treeList" :props="selectConfig"></el-tree>
    </template>
  </el-popover>
</template>
<script setup>
import {ElTreeSelect} from 'element-plus'
import {ref, computed, defineEmits, defineProps, watch} from 'vue'
import store from "@/store";
import {Select,UserFilled,Delete,Folder} from '@element-plus/icons-vue'
const isShow = ref(false);
const selectConfig = {
  label:"name",
  children:"children",
  value:"id",
};
const props = defineProps({
  users:{
    default(){
      return []
    }
  },
  selected:{
    default(){
      return {
          name:"",
          type:"",
          id:""
      }
    }
  }
})
const emit = defineEmits(["update:selected"]);
const treeList = computed({
  get(){
    return props.users;
  }
})
const changeSelect =(e)=>{
  emit("update:selected",e);
  isShow.value =false;
}
</script>
<style scoped lang="scss">
.user-only-select{
  padding: 0 12px;
  position: relative;
  background: #fff;
  cursor: pointer;
  display: block;
  border-radius: 5px;
  border:1px solid var(--el-border-color-hover);;
  height: 25px;
  width: 100%;
  &:hover{
    border-color: var(--el-text-color-secondary);;
  }
}
</style>
