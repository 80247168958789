<template>
<div class="chart-component">
  <div class="title">广告公司销售表</div>
  <el-form inline>
    <el-form-item></el-form-item>
  </el-form>
  <div id="gg-x-s-chart"></div>
</div>
</template>

<script setup>
import {Column} from "@antv/g2plot";
import {defineProps, onMounted, watch,ref} from 'vue'
import {getCellValue} from "@/utils";
const DataTemplate = ref([]);
DataTemplate.value =  [
  {title:"候车亭广告",value:0},
  {title:"路灯杆",value:0},
  {title:"交通护栏",value:0},
  {title:"户外广告牌",value:0},
  {title:"喷绘制作",value:0},
  {title:"承揽业务",value:0},
];

const props = defineProps({
  sheet:{
    default(){
      return [];
    }
  }
})
let chartObj;
const createChart = ()=>{
  chartObj = new Column("gg-x-s-chart",{
    data:DataTemplate.value,
    yField:"value",
    xField:"title",
    yAxis:{
      label:{
        formatter:(e)=>{
          return `${e}万元`;
        }
      }
    },
    label:{
      content:(e)=>{
        return `销售\n${e.value}万元`;
      }
    }
  })
  chartObj.render();
}
onMounted(createChart)
const updateChartData = (sheet)=>{
  DataTemplate.value.forEach((item,key)=>{
    let index = key+2;
    item.value = getCellValue(sheet,`B${index}`);
  })
  DataTemplate.value.sort(function (x,y){
    return -(x.value - y.value);
  })
  if(chartObj){
    chartObj.changeData(DataTemplate.value);
  }
}
watch(()=>props.sheet,(e)=>{
  console.log(e);
  if(e){
    updateChartData(e);
  }
},{deep:true,immediate:true});

</script>

<style scoped>

</style>
