import GGLiRunChart from './GuangGao/GGLiRunChart.vue';
import GGXiaoShouChart from './GuangGao/GGXiaoShouChart.vue';
import ZhanTingGuangGao from './GuangGao/ZhanTingGuangGao.vue';
import GeLiDaiGuangGao from './GuangGao/GeLiDaiGuangGao.vue';
import JiaKaoZhongXinDaPaiGuangGao from './GuangGao/JiaKaoZhongXinDaPaiGuangGao.vue';
import LuDengGanGuangGao from './GuangGao/LuDengGanGuangGao.vue';
import QingTianZhuGuangGoa from './GuangGao/QingTianZhuGuangGoa.vue';

import FuYuanShouLiChart from './JiaiKaoZhongXin/FuYuanShouLiChart.vue';
import SchoolHegeLvChart from './JiaiKaoZhongXin/SchoolHegeLvChart.vue';
import ShiCaoHeGeLvChart from './JiaiKaoZhongXin/ShiCaoHeGeLvChart.vue';
import ShouLiChart from './JiaiKaoZhongXin/ShouLiChart.vue';
import YeWuHeGeLvChart from './JiaiKaoZhongXin/YeWuHeGeLvChart.vue';

import ChanLiangChart from './ShiPin/ChanLiangChart.vue';
import ChuChengLvChart from './ShiPin/ChuChengLvChart.vue';
import CiPinChart from './ShiPin/CiPinChart.vue';
import GeBanKuaiShuJu from './YunYingGunaLi/GeBanKuaiShuJu.vue';
import ZhaoShengChart from './YunYingGunaLi/ZhaoShengChart.vue';
const components = {
    GGLiRunChart,
    GGXiaoShouChart,
    FuYuanShouLiChart,
    SchoolHegeLvChart,
    ShiCaoHeGeLvChart,
    ShouLiChart,
    YeWuHeGeLvChart,
    CiPinChart,
    ChuChengLvChart,
    ChanLiangChart,
    ZhaoShengChart,
    GeBanKuaiShuJu,
    ZhanTingGuangGao,
    GeLiDaiGuangGao,
    JiaKaoZhongXinDaPaiGuangGao,
    LuDengGanGuangGao,
    QingTianZhuGuangGoa

};

export default {
    install(Vue){
        Object.keys(components).forEach(key=>{
            Vue.component(key,components[key]);
        })
    }
}
