<template>
<el-switch :loading="loading" @before-change="changeValue" :value="value" :inactive-text="props.inactiveText" :active-text="props.activeText" :active-value="props.activeValue" :inactive-value="props.inactiveValue" />
</template>
<script setup>
import {computed, defineProps,ref} from 'vue';
const loading = ref(false);
const props = defineProps({
  activeValue:{
    default(){
      return 10
    }
  },
  inactiveValue:{
    default(){
      return 20
    }
  },
  activeText:{
    default(){
      return "启用"
    }
  },
  inactiveText:{
    default:"禁用"
  },
  value:{
   default:""
  },
})
const val = ref(null)
const value = computed({
  get(){
    return props.value;
  },
  set(v){
    val.value = v;
  }
})
const changeValue = (e)=>{
  console.log(e);
  // if(e){
  //   emit("change",{v:e,c:confirm});
  // }
}
// const confirm =(res)=>{
//   if(res){
//     emit("update:value",props.activeValue);
//   }else{
//     emit("update:value",props.inactiveValue);
//   }
// }

</script>

<style scoped>

</style>
