<template>
  <div class="chart-component">
    <div class="title">广告销售数据组成</div>
    <div id="GGLiRunChart"></div>
  </div>
</template>

<script setup>
import {Pie} from '@antv/g2plot'
import {defineProps, onMounted, ref, watch} from "vue";
import {getCellValue} from "@/utils";

const DataTemplate = ref([]);
DataTemplate.value = [
  {title:"成本",value:0},
  {title:"管理费用",value:0},
  {title:"财务费用",value:0},
  {title:"附加税",value:0},
  {title:"利润",value:0},
];
const props = defineProps({
  sheet:{
    default(){
      return null;
    }
  }
})
let chartObj;
const createChart = ()=>{
  chartObj = new Pie("GGLiRunChart",{
    padding:40,
    legend:false,
    data:DataTemplate.value,
    angleField:"value",
    colorField:"title",
    radius: 0.75,
    label: {
      type: 'outer',
      content: (e,d,f)=>{
        return `${e.title}\n${e.value}万元\n占比:${parseFloat(e.percent*100).toFixed(0)}%`
      }
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
  })
  chartObj.render();
}

const updateChartData = (sheet)=>{
  DataTemplate.value[0].value = getCellValue(sheet,"B12");
  DataTemplate.value[1].value = getCellValue(sheet,"B13");
  DataTemplate.value[2].value = getCellValue(sheet,"B14");
  DataTemplate.value[3].value = getCellValue(sheet,"B15");
  DataTemplate.value[4].value = getCellValue(sheet,"B17");
  DataTemplate.value.sort(function (x,y){
    return -(x.value - y.value);
  })
  if(chartObj){
    chartObj.destroy();
    createChart();
  }
}
watch(()=>props.sheet,(e)=>{
  console.log(e);
  if(e){
    updateChartData(e);
  }
},{deep:true,immediate:true});
onMounted(createChart)
</script>
<style scoped>

</style>
