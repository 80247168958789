export default [
    {name:"SettingIndex",path:"/admin/setting",component:()=>import("@/views/admin/Setting/SettingIndex.vue")},
    {name:"setting_work_wechat",path:"/admin/setting/app",component:()=>import("@/views/admin/Setting/AppSetting.vue")},
    /* 报表相关 */
    {name:"AdminFormIndex",path:"/admin/form",component:()=>import("@/views/admin/Form/FormIndex.vue")},
    {name:"AdminFormInfo",path:"/admin/form/info",component:()=>import("@/views/admin/Form/FormInfo.vue")},
    {name:"AdminDesignForm",path:"/admin/form/:form_id",component:()=>import("@/views/admin/Form/FormDesign.vue")},
    {name:"AdminDesignFlow",path:"/admin/flow/:form_id",component:()=>import("@/views/admin/Form/FlowDesign.vue")},
    {name:"AdminDesignFlow",path:"/admin/flow/:form_id",component:()=>import("@/views/admin/Form/FlowDesign.vue")},
    /* 流程相关 */
]
