<template>
<div class="form-layout">
  <div class="form-body">
    <slot></slot>
  </div>
  <div class="footer">
    <slot name="footer"></slot>
  </div>
</div>
</template>
<script>
export default {
  name: "FormLayout"
}
</script>

<style scoped lang="scss">
.form-layout{
  height: 100%;
  display: flex;
  flex-direction: column;
  .form-body{
    height: 100%;
  }
}
</style>