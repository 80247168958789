<template>
  <el-form inline>
    <el-form-item>
      <el-radio-group v-model="query.subject" @change="changeSubject">
        <el-radio-button label="1">科目一</el-radio-button>
        <el-radio-button label="4">科目四</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-radio-group v-model="query.type" @change="changeType">
        <el-radio-button label="region">按地区</el-radio-button>
        <el-radio-button label="title">按驾校</el-radio-button>
      </el-radio-group>
    </el-form-item>
  </el-form>
  <div id="school-chart"></div>
</template>
<script setup>
import {onMounted, nextTick, computed, defineProps, ref, watch} from "vue";
import {Bar} from '@antv/g2plot';
import _ from 'lodash'
import {getCellValue} from '@/utils';
const query = ref({
  type:"title",
  subject:"1"
})
let chart;
const SheetData = ref([]);
SheetData.value=[
  {region:"市区",title:"自培",value:0,total:0},
  {region:"市区",title:"宏大",value:0,total:0},
  {region:"市区",title:"力佳",value:0,total:0},
  {region:"市区",title:"佳南",value:0,total:0},
  {region:"市区",title:"顺通",value:0,total:0},
  {region:"市区",title:"信诚",value:0,total:0},
  {region:"市区",title:"鹏程",value:0,total:0},
  {region:"市区",title:"佳信",value:0,total:0},
  {region:"市区",title:"新途",value:0,total:0},
  {region:"市区",title:"龙运",value:0,total:0},
  {region:"市区",title:"砺学",value:0,total:0},
  {region:"市区",title:"远通",value:0,total:0},
  {region:"市区",title:"海东",value:0,total:0},
  {region:"市区",title:"平安",value:0,total:0},
  {region:"市区",title:"教育",value:0,total:0},
  {region:"市区",title:"环通",value:0,total:0},
  {region:"市区",title:"桦川",value:0,total:0},
  {region:"桦南",title:"桦南",value:0,total:0},
  {region:"富锦",title:"万里",value:0,total:0},
  {region:"富锦",title:"富通",value:0,total:0},
  {region:"富锦",title:"丰驰",value:0,total:0},
  {region:"富锦",title:"九龙",value:0,total:0},
  {region:"富锦",title:"鹏阳",value:0,total:0},
  {region:"抚远",title:"抚远",value:0,total:0},
  {region:"同江",title:"同江",value:0,total:0},
  {region:"汤原",title:"汤原",value:0,total:0},
];
const UpdateData = ()=>{
  if(chart){
    if(query.value.type === 'title'){
      chart.changeSize(chart.chart.width,1000)
      chart.changeData(SchoolData.value);
    }else if(query.value.type === 'region'){
      chart.changeSize(chart.chart.width,300)
      chart.changeData(RegionData.value.newData);
    }
    chart.update({
      yField:query.value.type,
    });
  }
}



const updateChartData = (sheet)=>{
  let column1 = "C";
  let column2 = "D";
  console.log(query.value.subject );
  if(query.value.subject === '4'){
    column1 = "F"
    column2 = "G"
  }
  let data = _.cloneDeep(SheetData.value);
  data.forEach((item,key)=>{
    let index = key+3;
    console.log(column1+index,column2+index);
    item.value = getCellValue(sheet,`${column2}${index}`);
    item.total = getCellValue(sheet,`${column1}${index}`);
  })
  SheetData.value = data;
  UpdateData();
}
watch(()=>props.sheet,(e)=>{
  updateChartData(e);
},{deep:true,immediate:true});
const heightList  = {school:1000,region:300}
const props = defineProps({
  sheet:{
    default(){
      return null;
    }
  }
})
// 生成驾校数据
const SchoolData = computed(()=>{
  let data = [];
  SheetData.value.forEach(item=>{
    let newItem = {
      title:item.title,
      value:item.value,
      total:item.total,
      region:item.region
    };
    let successItem = _.cloneDeep(newItem);
    successItem.success = item.value;
    successItem.type ='success';
    data.push(successItem);
    let errorItem = _.cloneDeep(newItem);
    errorItem.value = item.total-item.value;
    errorItem.type ='error';
    data.push(errorItem);
  })
  data.sort(function (x,y){
    return -(x.value - y.value);
  })
  return data;
})
// 生成区域数据
const RegionData = computed(()=>{
  let tempData = [];
  for (let p in SheetData.value){
    let item = SheetData.value[p];
    if(tempData[item['region']]){
      tempData[item['region']]['value'] += item.value;
      tempData[item['region']]['total'] += item.total;
    }else{
      tempData[item['region']] = _.cloneDeep(item);
    }
  }
  tempData = Object.values(tempData)
  let newData = [];
  tempData.forEach(item=>{
    let newItem = {
      title:item.title,
      value:item.value,
      total:item.total,
      region:item.region
    };
    let successItem = _.cloneDeep(newItem);
    successItem.success = item.value;
    successItem.type ='success';
    newData.push(successItem);
    let errorItem = _.cloneDeep(newItem);
    errorItem.value = item.total-item.value;
    errorItem.type ='error';
    newData.push(errorItem);
  });
  tempData.sort(function (x,y){
    return -(x.value - y.value);
  })
  newData.sort(function (x,y){
    return -(x.value - y.value);
  })
  return {data:tempData,newData:newData};
})
const changeType = ()=>{
  UpdateData();
}
const changeSubject = ()=>{
  updateChartData(props.sheet);
}
onMounted(()=>{
  nextTick(createChart)
})
const createChart = () =>{
  const {value} = SchoolData;
  chart = new Bar("school-chart", {
    height:1000,
    data:value,
    color:["#08f","#EEE"],
    isPercent:true,
    isStack:true,
        legend:false,
    yField: 'title',
    yAxis:{
      label:{
        formatter(e,v,index){
          let item;
          if(chart.options.yField === 'region'){
            item = RegionData.value.data[index];
          }else{
            item = SheetData.value[index];
          }
          return `${e}\n${item.total}人次`;
        },
      },
    },
    xField: 'value',
    seriesField:"type",
    label: {
      position: 'left',
      offsetY:0,
      offsetX:20,
      content:(e)=>{
        if(e.type === 'success'){
          return `合格:${e.success}人,合格率:${parseFloat(e.value*100).toFixed(2)}%`
        }
      },
      style: {
        fill: '#000',
        opacity: 0.6,
      },
    },
  }
  )
  chart.render();
}
</script>
<style scoped>

</style>
