<template>
  <div id="g2-container"></div>
</template>
<script setup>
import {onMounted, nextTick, defineProps, defineExpose, watch} from "vue";
import {Column} from '@antv/g2plot';
import {getCellValue} from '@/utils';
let ChartData = [
  {title:"驾照恢复",value:0,total:0,type:"合格"},
  {title:"驾照恢复",value:0,total:0,type:"不合格"},
  {title:"满分学习",value:0,total:0,type:"合格"},
  {title:"满分学习",value:0,total:0,type:"不合格"},
  {title:"军警外籍",value:0,total:0,type:"合格"},
  {title:"军警外籍",value:0,total:0,type:"不合格"},
  {title:"三力测试",value:0,total:0,type:"合格"},
  {title:"三力测试",value:0,total:0,type:"不合格"},
];
let chartContainer
const updateChartData = (sheet)=>{

  const data =
      [
        {success:getCellValue(sheet,"C2"),total:getCellValue(sheet,"B2")},
        {success:getCellValue(sheet,"C3"),total:getCellValue(sheet,"B3")},
        {success:getCellValue(sheet,"C4"),total:getCellValue(sheet,"B4")},
        {success:getCellValue(sheet,"C5"),total:getCellValue(sheet,"B5")},
      ]

  ChartData  =   [
      {title:"驾照恢复",value:data[0].total-data[0].success,total:data[0].total,type:"不合格"},
      {title:"驾照恢复",value:data[0].success,total:data[0].total,type:"合格"},

      {title:"满分学习",value:data[1].total-data[1].success,total:data[1].total,type:"不合格"},
      {title:"满分学习",value:data[1].success,total:data[1].total,type:"合格"},

      {title:"军警外籍",value:data[2].total-data[2].success,total:data[2].total,type:"不合格"},
      {title:"军警外籍",value:data[2].success,total:data[2].total,type:"合格"},

      {title:"三力测试",value:data[3].total-data[3].success,total:data[3].total,type:"不合格"},
      {title:"三力测试",value:data[3].success,total:data[3].total,type:"合格"},

  ];
  ChartData.sort(function (x,y){
    return -(x.value - y.value);
  })
  if(chartContainer){
    chartContainer.changeData(ChartData)
  }
}
defineExpose({
  updateChartData
})
/**      图标通用方法       **/

const props = defineProps({
  sheet:{
    default(){
      return null;
    }
  }
})

watch(()=>props.sheet,(e)=>{
  updateChartData(e);
},{deep:true,immediate:true});


onMounted(()=>{
  nextTick(createChart)
})
const createChart = () =>{
  chartContainer = new Column("g2-container",{
    data:ChartData,
    isPercent:true,
    isStack:true,
    yField: 'value',
    xField: 'title',
    seriesField: 'type',
    color:["#EEE","#08f"],
    legend:false,
    yAxis:{
      label:{
        formatter(e){
          return parseInt(e*100).toFixed(0)+"%";
        },
      }
    },
    xAxis:{
      label:{
        formatter(e){
          let total = 0;
          for (let p in ChartData){
            const item = ChartData[p];
            if(item.title === e){
              total = item.total;
              break;
            }
          }
          return `${e}\n${total}人次`;
        },
      }
    },
    label: {
      position: 'bottom',
      content:(e)=>{
        console.log(e);
        if(e.type ==='合格'){
          return `合格\n${e.value*e.total}人\n合格率\n${parseFloat(e.value*100).toFixed(2)}%`
        }
      },
      // 配置样式
      style: {
        fill: '#000',
        opacity: 0.6,
      },
    },
  })
  chartContainer.render();
}
</script>
