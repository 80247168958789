<template>
  <div class="user-select">
    <div>
      <el-space wrap>
        <template  v-if="props.hideList === false">
          <el-tag class="tags" size="large" effect="plain" closable v-for="(item,key) in props.selected" :key="key" @close="tagRemoveItem(key)">
            <el-icon>
              <UserFilled  v-if="item.type==='user'" />
              <Folder  v-if="item.type==='department'"/>
            </el-icon>
            <span>{{item.name}}</span>
          </el-tag>
        </template>
        <el-link @click="openSetting" type="primary">修改</el-link>
      </el-space>
    </div>
  </div>
  <el-dialog append-to-body v-model="data.isShow" title="人员选择">
    <div class="user-select-dialog">
      <div class="departments">
        <el-input v-model="data.filterText" placeholder="搜索" />
        <el-tree  ref="treeRef" :filter-node-method="filterNode" :expand-on-click-node="false" check-strictly default-expand-all	:data="treeList" :props="{label:'name',value:'id'}">
          <template #default="{node,data}">
            <div class="node-item" @click="selectItem(data,node)">
              <el-icon>
                <UserFilled  v-if="data.type==='user'" />
                <Folder  v-if="data.type==='department'"/>
              </el-icon>
              <div class="name">{{data.name}}</div>
              <div v-if="inSelected(data) > -1">
                <el-icon>
                  <Select />
                </el-icon>
              </div>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="selected">
        <div class="item" v-for="(item,key) in data.selected" :key="key">
          <el-icon>
            <UserFilled  v-if="item.type==='user'" />
            <Folder  v-if="item.type==='department'"/>
          </el-icon>
          <div class="name">{{item.name}}</div>
          <div class="remove" @click="removeItem(key)">
            <el-icon><Delete /></el-icon>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button @click="data.isShow = false">取消</el-button>
      <el-button type="primary" @click="confirmSelect">确认</el-button>
    </template>
  </el-dialog>
</template>
<script setup>
import {computed, defineEmits, defineProps, reactive, watch,ref} from 'vue'
import {Select,UserFilled,Delete,Folder} from '@element-plus/icons-vue'
import store from "@/store";
const treeRef = ref(null);
const data = reactive({
    selected:[],
    isShow:false,
    list:[],
    filterText:""
    })
const filterNode = (value,data)=>{
  if (!value) return true
  return data.name.includes(value)
}

watch(()=>data.filterText, (val) => {
  treeRef.value.filter(val)
})
  const emit = defineEmits(["update:selected"])
  const props = defineProps({
    users:{
      default(){
        return [];
      }
    },
    hideList:{
      default(){
        return false
      }
    },
    selected:{
      default(){
        return []
      }
    }
  })
  const treeList = computed({
    get(){
      if( props.users && props.users.length > 0){
        return props.users;
      }
      return store.getters.departments;
    }
  })
  const openSetting =()=>{
    if(typeof props.selected === 'object'){
      data.selected = props.selected;
    }
    data.isShow = true;
  }
  const tagRemoveItem =(index)=>{
    data.selected.splice(index,1);
    emit("update:selected",data.selected);
  }
  const removeItem =(index)=>{
    data.selected.splice(index,1);
  }
  const selectItem  = (e,d)=>{
      let index =inSelected(e)
      if(index === -1){
        let item = {
          name:e.name,id:e.id,type:e.type,
        };
        if(e.type ==='user'){
          item.name = `[${d.parent.data.name}] ${item.name}`;
        }
        data.selected.push(item);
      }else{
        data.selected.splice(index,1);
      }
  }
  const inSelected = (e)=>{
    let index = -1;
    for (let i = 0 ; i < data.selected.length ; i++){
      let item = data.selected[i];
      if(
          (e.id == item.id)
      ){
        index = i;
      }
    }
    return index;
  }
  const confirmSelect = ()=>{
    emit("update:selected",data.selected);
    data.isShow =false;
  }

  const updateUsers =()=>{

  }

</script>
<style scoped lang="scss">
.user-select-dialog{
  display: flex;
    .departments{
      user-select: none ;
      border-right: 1px solid #eee;
      padding-right: 20px;
      width:360px;
      height: 330px;
      overflow: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
      .node-item{
        align-items: center;
        .el-icon{
          margin-right: 10px;
        }
        .name{
          flex: 1;
        }
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    .selected{
      padding: 0 15px;
      flex: 1;
      .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-icon{
          margin-right: 10px;
        }
        .name{
          flex: 1;
        }
        .remove{
          cursor: pointer;
        }
      }
    }
}
</style>
