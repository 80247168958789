<template>
  <div class="node-item" @click="openSetting({f:settingComplete,d:config})">
    <div class="custom-node" :class="config.type">
      <div class="header">
        <div class="title">{{tipsInfo.title}}</div>
        <div class="remove" v-if="props.isStart == null && props.isEnd == null" @click.stop="removeItem">
          <el-icon><Close /></el-icon>
        </div>
      </div>
      <div class="content" v-html="parseInfo">

      </div>
    </div>
    <div class="add-btn">
      <span v-if="btnType === 'add'" class="btn" @click.stop="showBranch()"></span>
      <span v-else-if="btnType === 'switch'" class="addSwitchBtn" @click.stop="addBranch">新增条件</span>
    </div>
  </div>
  <div class="flow-container" v-if="config.children && config.children.length > 0">
    <div v-for="(item,key) in config.children" :key="key" class="branch-wrap item">
      <div class="cross-left"></div>
      <div class="cross-right"></div>
      <CustomNode @openSetting="openSetting" :parent="config.children" v-model:config="config.children[key]" :index="key"></CustomNode>
    </div>
  </div>
</template>
<script setup>
import {defineEmits, defineProps, computed, reactive} from 'vue';
import {ElMessageBox,ElSelect,ElOption} from 'element-plus'
import {Close} from '@element-plus/icons-vue'
import CustomNode from '@/components/CustomNode'
const x = reactive({
  isShow : false,
  type:"audit",
  title:""
})
const props = defineProps({
  isStart:{
    default(){
      return null
    }
  },
  isEnd:{
    default(){
      return null
    }
  },
  index:{
    default(){
      return 0
    }
  },
  config:{
    default(){
      return {
        title:"",
        config:{},
        type:""
      }
    }
  },
  parent:{
    default(){
      return []
    }
  }
})
const nodes = computed({
  get(){
    return props.parent;
  },
  set(value){
    emit("update:parent",value);
  }
})
const openSetting = (value)=>{
  emit("openSetting",value)
}
const settingComplete =(value)=>{
  console.log(value);
  emit("update:config",value);
}
const emit = defineEmits(["update:config",'update:parent']);
const config = computed({
  get(){
    return props.config;
  },
  set(value){
    emit("update:config",value);
  }
})
const tipsInfo = computed(()=>{
  let title;
  let tip;
  switch (config.value.type){
    case "creator": title="申报人";tip="请选择申报人";break;
    case "audit":title="审核人";tip= "请选择审核人";break;
    case "end" : title="结束";break;
    case "switch": title="填写人分支";tip="请配置条件";break;
  }
  if(config.value.title){
      title = config.value.title;
  }
  return {title:title,tip:tip};
})
const parseInfo = computed(()=>{
  let val = "";
  try{
    if(config.value.type === "creator" || config.value.type === "audit"  || config.value.type === "switch"){
      console.log(config.value);
      config.value.config.rule.forEach((item,key)=>{
        if(key > 0){
          val+=",";
        }
        val += item.name;
      })
    }else{
      val =  tipsInfo.value.tip;
    }
  }catch (e){
    console.log(e);
    val =  tipsInfo.value.tip;
  }
  return val;
})
const showBranch = ()=>{
  if(props.isStart != null){
    emit("addItem")
  }else{
    if(!config.value.children){
      config.value.children  = [];
    }
    config.value.children.splice(0,0,{type:"audit",title:""});
  }
}
const removeItem =()=>{
  ElMessageBox.confirm("该条件分支下的所有节点将被删除，确认继续？","提示",{confirmButtonText:"确认删除",cancelButtonText:"取消",type:"warning"}).then(()=>{
    nodes.value.splice(props.index,1);
  })
}

const btnType = computed(()=>{
  if(props.isStart !== null){
    if(nodes.value && nodes.value.length > 0 &&  nodes.value[0].type === 'switch'){
      return "switch";
    }else{
      return "add";
    }
  }else{
    if(config.value.children && config.value.children.length > 0 &&  config.value.children[0].type === 'switch'){
      return "switch";
    }else{
      return "add";
    }
  }
})
const addBranch =()=>{

  if(props.isStart === null){
    config.value.children.push({
      title:"",type:"switch"
    })
  }else{
   nodes.value.push({
     title:"",type:"switch"
   })
  }
}
</script>
<style scoped lang="scss">
:root{
  --node-primary-color:transparent;
  --node-bg-color:#fff;
}
.node-item{
  user-select: none;
  position: relative;
  padding:70px 25px;
  .custom-node{
    cursor: pointer;
    &.end{
      --node-primary-color:#4A94FF;
      --node-bg-color:#fff;
    }
    &.creator{
      --node-primary-color:#4A94FF;
      --node-bg-color:#E9F2FF;
    }
    &.audit{
      --node-primary-color:#fcad22;
      --node-bg-color:#FFF9EE;
    }
    &.switch{
      --node-primary-color:#88939f;
      --node-bg-color:#EEF4FB;
    }
    &.end{
      --node-primary-color:#3CB4B2;
      --node-bg-color:#E6F8F8;
    }
    font-size: 14px;
    width:250px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 10%);
    border-top:3px solid var(--node-primary-color);
    .header{
      display: flex;
      justify-content: space-between;
      background: var(--node-bg-color);
      color: var(--node-primary-color);
      padding:10px 15px;
      .title{
        flex: 1;
      }
      .remove:hover{
        color: red;
      }
    }
    .content{
      justify-content: flex-start;
      flex-direction: column;
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 10px;
      color: #6c6b6b;
    }
  }
  .add-btn{
    z-index:9999;
    position: absolute;
    bottom: -15px;
    display: flex;
    justify-content: center;
    width:250px;
    .addSwitchBtn{
      cursor: pointer;
      box-shadow: 0 0 4px 4px #eee;
      background-color: #fff;
      font-size: 14px;
      padding: 4px 10px;
      border-radius: 2px;
      &:hover{
        background-color: #eee;
      }
    }
    .btn{
      position: relative;
      outline: none;
      width: 30px;
      height: 30px;
      background-color: #A0B5C8;
      border-radius: 50%;
      line-height: 30px;
      cursor: pointer;
      &:before{
        content: '';
        position: absolute;
        display: inline-block;
        width: 2px;
        height: 16px;
        left: 14px;
        top: 7px;
        background-color: #fff;
      }
      &:after{
        content: '';
        position: absolute;
        display: inline-block;
        height: 2px;
        width: 16px;
        top: 14px;
        left: 7px;
        background-color: #fff;
      }
      &:hover{
        background-color: #8599AA;
      }
    }
  }
}
</style>
