import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import element from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn'
import 'element-plus/dist/index.css'
import custom from '@/components'
import '@/assets/styles.scss'
import charts  from '@/components/Charts';
const app = createApp(App);
app.use(store).use(router).use(element,{locale:zhCn}).use(custom).use(charts).mount('#app')
