<template>
<div class="chart-component">
  <div id="ztgg"></div>
</div>
</template>

<script setup>
import {computed,ref,onMounted,watch,defineProps} from 'vue'
import {Bar} from "@antv/g2plot";
import {getCellValue} from "@/utils";
import _ from 'lodash'
const props = defineProps({
  sheet:{
    default(){
      return [];
    }
  }
})
let ChartObj;
const TemplateData  = [
  {title:"中国移动（南）",value:0},
  {title:"中国移动（北）",value:0},
  {title:"前进区政府（南）",value:0},
  {title:"前进区政府（北）",value:0},
  {title:"中心广场（南）",value:0},
  {title:"中心广场（北）",value:0},
  {title:"新玛特（南）",value:0},
  {title:"新玛特（北）",value:0},
  {title:"百货大楼（南）",value:0},
  {title:"百货大楼（北）",value:0},
  {title:"电脑城（南）",value:0},
  {title:"电脑城（北）",value:0},
  {title:"商业城（南）",value:0},
  {title:"商业城（北）",value:0},
  {title:"长安桥（南）",value:0},
  {title:"长安桥（北）",value:0},
  {title:"市图书馆（南）",value:0},
  {title:"技师学院（南）",value:0},
  {title:"市机关中心（南）",value:0},
  {title:"市机关中心（北）",value:0},
  {title:"百货大楼西门（东）",value:0},
  {title:"百货大楼西门（西）",value:0},
  {title:"滨江名筑（北）",value:0},
  {title:"江天宾馆（北）",value:0},
  {title:"红旗家具城（东）",value:0},
  {title:"红旗家俱城（西）",value:0},
  {title:"六小（东）",value:0},
  {title:"中心医院（西）",value:0},
  {title:"桥南市场（东）",value:0},
  {title:"桥南市场（西）",value:0},
  {title:"唐人中心（西）",value:0},
  {title:"顺和路口（南）",value:0},
  {title:"佳木斯大学（西）",value:0},
  {title:"中山客运站东（南）",value:0},
  {title:"中山客运站东（北）",value:0},
  {title:"站前邮局（北）",value:0},
  {title:"通园巷（北）",value:0},
  {title:"火车站（东）",value:0},
  {title:"火车站（西）",value:0},
  {title:"西林公园（东）",value:0},
  {title:"西林公园（西）",value:0},
  {title:"中心广场（北）",value:0},
  {title:"教育小区（南）",value:0},
  {title:"教育小区（北）",value:0},
  {title:"体育馆（南）",value:0},
  {title:"农校（北）",value:0},
  {title:"农垦大院（东",value:0}


];
const ChartData = computed(()=>{
  let data = [];
  TemplateData.forEach((item,key)=>{
    let success = _.cloneDeep(item);
        success.type ="已卖出"
        success.val = success.value = _.cloneDeep(item.success);

        data.push(success);
    let value = _.cloneDeep(item);
        value.type ="剩余"
        value.val = value.value = _.cloneDeep(item.value);
        data.push(value);
  })
  data.sort(function (x,y){
    return -(x.value - y.value);
  })
  return data;
})
const UpdateData = (sheet)=>{
  TemplateData.forEach((item,key)=>{
     let index = key+13;
     let success = getCellValue(sheet,`F${index}`);
     let total  =  getCellValue(sheet,`E${index}`);
     item.success = success
     item.value =   total - success;
     console.log(total,success);
  });
  if(ChartObj){
    ChartObj.destroy();
    createChart();
  }
}
watch(()=>props.sheet,(e)=>{
  UpdateData(e);
},{deep:true,immediate:true});
const createChart = ()=>{
  ChartObj = new Bar("ztgg",{
    height:2000,
    data:ChartData.value,
    xField:"value",
    yField:"title",
    isPercent: true,
    isStack: true,
    seriesField: 'type',
    label: {
      position: 'middle',
      content: (item) => {
        if(item.value > 0){
          return `${item.type}:${item.val},占比:${parseFloat(item.value*100).toFixed(2)}%`
        }
      },
      style: {
        fill: '#fff',
      },
    },
  })
  ChartObj.render();
}
onMounted(createChart)

</script>

<style scoped>

</style>