<template>
<div class="tag-manager">
  <el-tag closable v-for="(item,key) in tags" :key="key" @close="handleClose(key)">
    {{item}}
  </el-tag>
  <el-input
            v-model="inputValue"
            ref="InputRef"
            v-if="showInput"
            size="small"
            class="tag-input"
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm" />
  <el-button v-else size="small" type="primary" @click="showAdd">{{props.title}}</el-button>
</div>
</template>

<script setup>
  import {computed,defineEmits,defineProps,ref,nextTick} from 'vue'
  const inputValue = ref("")
  const showInput = ref(false);
  const InputRef = ref(null)
  const emit  = defineEmits(["update:tags"])
  const props = defineProps({
    tags:{
      default(){
        return []
      }
    },
    title:{
      default(){
        return "新增"
      }
    }
  })
  const handleInputConfirm =()=>{
    if (inputValue.value) {
      tags.value.push(inputValue.value)
    }
    showInput.value = false
    inputValue.value = ''
  }
  const  showAdd =()=>{
    showInput.value = true;
    nextTick(() => {
      InputRef.value.input.focus()
    })
  }
  const handleClose = (index)=>{
    tags.value.splice(index,1);
  }
  const tags = computed({
    get(){
      if(typeof props.tags === 'object'  && Object.hasOwnProperty.call(props.tags,"length")){
        return props.tags;
      }
      return [];
    },
    set(val){
      emit("update:tags",val);
    }
  })
</script>

<style scoped lang="scss">
.tag-manager{
  .el-tag{
    margin-right: 10px;
  }
  .tag-input{
    width: 100px;
  }
}
</style>
