<template>
  <div class="title">实操合格率</div>
  <el-form inline>
    <el-form-item>
      <el-radio-group v-model="query.subject" @change="changeSubject">
        <el-radio-button label="2">科目二</el-radio-button>
        <el-radio-button label="3">科目三</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-radio-group v-model="query.type" @change="changeType">
        <el-radio-button label="title">按驾校</el-radio-button>
        <el-radio-button label="car">按车型</el-radio-button>
      </el-radio-group>
    </el-form-item>
  </el-form>
<div id="schgl"></div>
</template>

<script setup>
import {Bar} from '@antv/g2plot'
import _ from 'lodash'
import { onMounted, defineProps, computed, ref, watch} from 'vue'
import {getCellValue} from "@/utils";
const query = ref({
  type:"title",
  subject:"2"
})
const ChartData = ref([]);
ChartData.value = [
  {region:"市区",title:"自培",car:"C1",value:0,total:0},
  {region:"市区",title:"自培",car:"C2",value:0,total:0},
  {region:"市区",title:"宏大驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"宏大驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"宏大驾校",car:"A1",value:0,total:0},
  {region:"市区",title:"宏大驾校",car:"A2",value:0,total:0},
  {region:"市区",title:"宏大驾校",car:"A3",value:0,total:0},
  {region:"市区",title:"宏大驾校",car:"B1",value:0,total:0},
  {region:"市区",title:"宏大驾校",car:"B2",value:0,total:0},
  {region:"市区",title:"力佳驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"力佳驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"力佳驾校",car:"C5",value:0,total:0},
  {region:"市区",title:"力佳驾校",car:"A1",value:0,total:0},
  {region:"市区",title:"力佳驾校",car:"A2",value:0,total:0},
  {region:"市区",title:"力佳驾校",car:"A3",value:0,total:0},
  {region:"市区",title:"力佳驾校",car:"B1",value:0,total:0},
  {region:"市区",title:"力佳驾校",car:"B2",value:0,total:0},
  {region:"市区",title:"佳南分校",car:"C1",value:0,total:0},
  {region:"市区",title:"佳南分校",car:"C2",value:0,total:0},
  {region:"市区",title:"顺通驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"顺通驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"信诚驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"信诚驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"鹏程驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"鹏程驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"佳信驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"佳信驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"新途驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"新途驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"龙运驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"龙运驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"砺学驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"砺学驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"远通驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"远通驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"海东驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"海东驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"平安驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"平安驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"教育驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"教育驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"环通驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"环通驾校",car:"C2",value:0,total:0},
  {region:"市区",title:"桦川驾校",car:"C1",value:0,total:0},
  {region:"市区",title:"桦川驾校",car:"C2",value:0,total:0},
  {region:"桦南",title:"桦南驾校",car:"C1",value:0,total:0},
  {region:"桦南",title:"桦南驾校",car:"C2",value:0,total:0},
  {region:"富锦",title:"万里驾校",car:"C1",value:0,total:0},
  {region:"富锦",title:"富通驾校",car:"C1",value:0,total:0},
  {region:"富锦",title:"丰驰驾校",car:"C1",value:0,total:0},
  {region:"富锦",title:"九龙驾校",car:"C1",value:0,total:0},
  {region:"富锦",title:"鹏阳驾校",car:"C1",value:0,total:0},
  {region:"富锦",title:"鹏阳驾校",car:"C2",value:0,total:0},
  {region:"抚远",title:"抚远驾校",car:"C1",value:0,total:0},
  {region:"抚远",title:"抚远驾校",car:"C2",value:0,total:0},
  {region:"同江",title:"同江驾校",car:"C1",value:0,total:0},
  {region:"汤原",title:"汤原驾校",car:"C1",value:0,total:0},
];
const props = defineProps({
  sheet:{
    default(){
      return null;
    }
  }
});
const SchoolChartData = computed(()=>{
  let tempData = [];
  ChartData.value.forEach(item=>{
    if(tempData[item.title]){
      tempData[item.title]['value'] += item.value;
      tempData[item.title]['total'] += item.total;
    }else{
      tempData[item.title] = _.cloneDeep(item);
    }
  });

  tempData = Object.values(tempData)
  let newData = [];
  tempData.forEach(item=>{
    let newItem = {
      title:item.title,
      value:item.value,
      total:item.total,
      car:item.car
    };
    let successItem = _.cloneDeep(newItem);
    successItem.success = item.value;
    successItem.type ='success';
    newData.push(successItem);
    let errorItem = _.cloneDeep(newItem);
    errorItem.value = item.total-item.value;
    errorItem.type ='error';
    newData.push(errorItem);
  });
  tempData.sort(function (x,y){
    return x.value - y.value;
  })
  newData.sort(function (x,y){
    return x.value - y.value;
  })
  return {data:tempData,newData:newData};
})
const CarChartData = computed(()=>{
  let tempData = [];
  ChartData.value.forEach(item=>{
    if(tempData[item.car]){
      tempData[item.car]['value'] += item.value;
      tempData[item.car]['total'] += item.total;
    }else{
      tempData[item.car] = _.cloneDeep(item);
    }
  });
  tempData = Object.values(tempData)
  let newData = [];
  tempData.forEach(item=>{
    let newItem = {
      title:item.title,
      value:item.value,
      total:item.total,
      car:item.car
    };
    let successItem = _.cloneDeep(newItem);
    successItem.success = item.value;
    successItem.type ='success';
    newData.push(successItem);
    let errorItem = _.cloneDeep(newItem);
    errorItem.value = item.total-item.value;
    errorItem.type ='error';
    newData.push(errorItem);
  });
  tempData.sort(function (x,y){
    return -(x.value - y.value);
  })
  newData.sort(function (x,y){
    return -(x.value - y.value);
  })
  return {data:tempData,newData:newData};
})
let chartObj;
const createChart = ()=>{
  chartObj = new Bar("schgl",{
      data:SchoolChartData.value.newData,
      height:1200,
      color:["#08f","#EEE"],
      isPercent:true,
      isStack:true,
    legend:false,
      xField:"value",
      yField:"title",
      seriesField:"type",
      yAxis:{
        label:{
          formatter(e,v,index){
            let item;
            if(chartObj.options.yField === 'title'){
              item = SchoolChartData.value.data[index];
            }else{
              item = CarChartData.value.data[index];
            }
            return `${e}\n${item.total}人次`;
          },
        },
      },
      label: {
        position: 'left',
        offsetY:0,
        content:(e,d,index,x)=>{
          if(e.type === 'success'){
            return  `合格人数:${e.success},合格率${parseFloat(e.value*100).toFixed(2)}%`
          }
        },
        // 配置样式
        style: {
          fill: '#000',
          opacity: 0.6,
        },
      },
  })
  chartObj.render();
}
const changeType =()=>{
  updateData();
}
const updateData = ()=>{
  console.log("开始更新数据");
 if(chartObj != null){
   if(query.value.type === 'title'){
     chartObj.changeSize(chartObj.chart.width,1200)
     chartObj.changeData(SchoolChartData.value.newData);
   }else if(query.value.type === 'car'){
     chartObj.changeSize(chartObj.chart.width,500)
     chartObj.changeData(CarChartData.value.newData);
   }
   chartObj.update({
     yField:query.value.type,
   });
 }
}

const updateChartData = (sheet)=>{
  let column1 = "D";
  let column2 = "E";
  if(query.value.subject === '3'){
    column1 = "G"
    column2 = "H"
  }
  let data = _.cloneDeep(ChartData.value);
  data.forEach((item,key)=>{
    let index = key+3;
    item.value = getCellValue(sheet,`${column2}${index}`);
    item.total = getCellValue(sheet,`${column1}${index}`);
  })
  ChartData.value = data;
  updateData();
  console.log(chartObj);
}
const changeSubject = ()=>{
  updateChartData(props.sheet);
}
watch(()=>props.sheet,(e)=>{
  updateChartData(e);
},{deep:true,immediate:true});

onMounted(createChart);
</script>

<style scoped>

</style>
