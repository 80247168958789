import { createStore } from 'vuex'
import cookie from 'js-cookie'
import persistedstate from 'vuex-persistedstate'
export default createStore({
  state: {
    userinfo:[],
    departments:[],
  },
  getters: {
    departments:state=>{
      return state.departments;
    },
    isMobile:()=>{
      const info =  navigator.userAgent;
      return /mobile/i.test(info);
    },
    isSuper:state=>{
      return 1;
    }
  },
  mutations: {
    updateDepartments:(state,data)=>{
      console.log(data);
      state.departments = data;
    }
  },
  actions: {

  },
  plugins:[
    persistedstate({
      storage:{
        getItem:key=>cookie.get(key),
        setItem:(key,value)=>cookie.set(key,value),
        removeItem:key=>cookie.remove(key)
      }
    })
  ]
})
