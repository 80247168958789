<template>
<div class="chart-component">
  <div class="title">招生数据</div>
  <div id="j-x-z-s"></div>
</div>
</template>

<script setup>
import {Bar} from "@antv/g2plot";
import {defineProps, ref, onMounted, computed, watch} from 'vue'
import {getCellValue} from "@/utils";
import _ from 'lodash'
const DataTemplate =ref([])
DataTemplate.value =  [
  {title:"服务公司",big:0,small:0},
  {title:"力佳驾校",big:0,small:0},
  {title:"泽通公司",big:0,small:0},
  {title:"佳南分校",big:0,small:0},
  {title:"宏大驾校",big:0,small:0},
  {title:"顺通驾校",big:0,small:0},
];

const props = defineProps({
  sheet :{
    default(){
      return []
    }
  }
});
const ChartData = computed(()=>{
  let data = [];
  DataTemplate.value.forEach(item=>{
    let bigItem = _.cloneDeep(item);
    bigItem.type = "大车";
    bigItem.value = bigItem.val =item.big;
    data.push(bigItem);
    let smallItem = _.cloneDeep(item);
    smallItem.type = "小车";
    smallItem.value = smallItem.val =item.small;
    data.push(smallItem);
  })
  data.sort(function (x,y){
    return -(x.value - y.value);
  })
  return data;
})
let chartObj;
const createChart = ()=>{
  chartObj =  new Bar("j-x-z-s",{
    data:ChartData.value,
    xField:"value",
    yField:"title",
    legend:false,
    isStack:true,
    isPercent:true,
    seriesField:"type",
    label:{
      content:(e)=>{
        var total = 0;
        ChartData.value.forEach(item=>{
          if(item.title === e.title){
            total += item.value;
          }
        });
        return  `${e.type} ${e.val}人 \n${parseFloat(e.val/total*100).toFixed(2)}%`
      }
    },
    yAxis:{
      label:{
        formatter:(e)=>{
          var total = 0;
          ChartData.value.forEach(item=>{
            if(item.title === e){
              total += item.value;
            }
          })
          return `${e}\n招生:${total}人`
        }
      }
    },
    xAxis:{
      label:{
        formatter(e){
          return parseFloat(e*100).toFixed(2)+"%";
        }
      }
    }
  })
  chartObj.render();
}
onMounted(createChart)
const updateChartData = (sheet)=>{
  DataTemplate.value.forEach((item,key)=>{
    let index = key+3;
    item.small = getCellValue(sheet,`B${index}`);
    item.big = getCellValue(sheet,`C${index}`);
  });
  if(chartObj){
    chartObj.changeData(ChartData.value);
  }
  console.log(DataTemplate.value)

}
watch(()=>props.sheet,(e)=>{
  if(e){
    updateChartData(e);
  }
},{deep:true,immediate:true});




</script>

<style scoped>

</style>
