<template>
  <router-view/>
</template>
<!-- 用于注册 jssdk-->
<script>
  import _ from "lodash";
  import {get} from '@/utils'
  export default {
    created(){
      this.getJSSDKConfig();
    },
    methods:{
      initJsSdk(config,work_config){
        const {wx}  = window;
        wx.ready(function (){
          wx.agentConfig(work_config);
        })
        wx.config(config);
      },
      getJSSDKConfig(){
        get("/api/index/initJSSDK",{}).then(res=>{
          const {config,work_config} = res.data;
          this.initJsSdk(config,work_config);
        })
      }
    },
  }
</script>