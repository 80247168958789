<template>
<div class="chart-component">
  <div class="title">驾培驾考板块各单位数据</div>
  <el-form inline>
    <el-form-item>
      <el-radio-group @change="updateData(props.sheet)" v-model="query.subject">
        <el-radio-button :label="0">科目一</el-radio-button>
        <el-radio-button :label="1">科目二</el-radio-button>
        <el-radio-button :label="2">科目三</el-radio-button>
        <el-radio-button :label="3">科目四</el-radio-button>
      </el-radio-group>
    </el-form-item>
  </el-form>
  <div id="g-b-k-d-y-s-j"></div>
</div>
</template>
<script setup>
import {defineProps,computed,ref,watch,onMounted} from "vue";
import {Bar, Column} from "@antv/g2plot";
import {getCellValue} from "@/utils";
import _ from 'lodash'
const props =defineProps({
  sheet:{
    default(){
      return null;
    }
  }
})
const position = [
  {total:{small:"B",big:"C"},success:{small:"D",big:"E"}},
  {total:{small:"I",big:"J"},success:{small:"K",big:"L"}},
  {total:{small:"Q",big:"R"},success:{small:"S",big:"T"}},
  {total:{small:"W",big:"X"},success:{small:"Y",big:"Z"}},
];
const query = ref({
  subject:0,
  type:"school"
});
let ChartObj;
const DataTemplate = ref([])
DataTemplate.value = [
  {title:"力佳驾校",big:{value:0,total:0},small:{value:0,total:0}},
  {title:"佳南分校",big:{value:0,total:0},small:{value:0,total:0}},
  {title:"服务公司",big:{value:0,total:0},small:{value:0,total:0}},
  {title:"宏大驾校",big:{value:0,total:0},small:{value:0,total:0}},
  {title:"顺通驾校",big:{value:0,total:0},small:{value:0,total:0}},
];
const ChartData =computed(()=>{
  let data = [];
  DataTemplate.value.forEach((item)=>{

    // 大车合格
    let bigSuccess = item.big.value;
    data.push({
      title:item.title,
      type:"大车",
      cate:"合格",
      value:bigSuccess,
      val:_.cloneDeep(bigSuccess),
    })
    // 大车不合格
    let bigError =item.big.total - item.big.value;
    data.push({
      title:item.title,
      type:"大车",
      cate:"不合格",
      value:bigError,
      val:_.cloneDeep(bigError),
    })
    //小车合格
    let smallSuccess = item.small.value;
    data.push({
      title:item.title,
      type:"小车",
      cate:"合格",
      value:smallSuccess,
      val:_.cloneDeep(smallSuccess),
    })
    //小车不合格
    let smallError = item.small.total - item.small.value;
    data.push({
      title:item.title,
      type:"小车",
      cate:"不合格",
      value:smallError,
      val:_.cloneDeep(smallError)
    })
  })
  return data;
})

const updateData = (sheet)=>{
  let p = position[query.value.subject];
  DataTemplate.value.forEach((item,key)=>{
    let index = key+4;
    item.big.value = getCellValue(sheet,`${p.success.big}${index}`);
    item.big.total = getCellValue(sheet,`${p.total.big}${index}`);
    item.small.value = getCellValue(sheet,`${p.success.small}${index}`);
    item.small.total =getCellValue(sheet,`${ p.total.small}${index}`);
  })
  DataTemplate.value.sort(function (x,y){
    return -(x.value - y.value);
  })
  if(ChartObj){
    ChartObj.destroy();
    createChart();
  }
}
const createChart = ()=>{
  ChartObj = new Bar("g-b-k-d-y-s-j",{
    data:ChartData.value,
    height:500,
    legend:false,
    xField:"value",
    yField:"title",
    isGroup: true,
    isStack: true,
    isPercent: true,
    columnWidthRatio: 0.9,
    seriesField: 'cate',
    groupField: 'type',
    label:{
      position:"left",
      content:(e)=>{
        if(e.cate==='合格' && e.value > 0){
          return `${e.type} 合格:${e.val}人 (${parseFloat(e.value*100).toFixed(2)})`
        }
      }
    },
    tooltip: {
      customItems:(originalItems)=>{
        return originalItems.filter(item=>{
          return item.data.cate==='合格';
        })
      },
      fields:["val",'value','cate','type'],
      formatter: (datum) => {
        console.log(datum)
        if(datum.cate==='合格'){
          return { name: datum.type, value: `合格:${datum.val}人(${parseFloat(datum.value*100).toFixed(2)}%)` };
        }
        return {name:datum.type,value:`不合格:${datum.val}人`}
      },
    }
  })
  ChartObj.render();
}
watch(()=>props.sheet,(e)=>{
  updateData(e);
},{deep:true,immediate:true});

onMounted(createChart);
</script>

<style scoped>

</style>
