<template>
  <el-dialog v-model="isShow">
    <el-form-item label="名称">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="状态">
      <el-switch v-model="form.status" :inactive-value="20" :active-value="10"  active-text="启用" inactive-text="禁用"></el-switch>
    </el-form-item>
    <el-form-item label="字段">
      <el-button @click="addItem" type="primary" size="small">新增字段</el-button>
    </el-form-item>
    <el-form-item>
      <el-table :data="form.fields">
        <el-table-column label="状态">
          <template #default="{row}">
            <el-tag v-if="row.delete_time > 0">已删除</el-tag>
            <el-tag v-else-if="row.field_id">正常</el-tag>
            <el-tag v-else>新增</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="主分类">
          <template #default="{row}">
            <el-input v-model="row.main_cate"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="次分类">
          <template #default="{row}">
            <el-input v-model="row.sub_cate"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="取值">
          <template #default="{row}">
            <el-input v-model="row.data"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="{row,$index}">
            <el-button v-if="row.delete_time === 0" size="small" type="danger" @click="removeItem(row,$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
  </el-dialog>
</template>
<script setup>
import {ref,defineProps,defineEmits,defineExpose} from 'vue'
const props = defineProps({})
const emit = defineEmits([]);
const form = ref({
  fields:[]
});
const isShow = ref(false);
const openDialog = ()=>{
  isShow.value = true;
}
const addItem =()=>{
  form.value.fields.push({delete_time:0});
}
const removeItem = (item,index)=>{
    if(item.field_id){
      form.value.fields[index].delete_time = Math.ceil(new Date().getTime() / 1000);
    }else{
      form.value.fields.splice(index,1);
    }
}
defineExpose({openDialog})
</script>
<style scoped lang="scss">
</style>
