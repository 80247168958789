<template>
  <div class="chart-component">
    <div id="gldgg"></div>
  </div>
</template>

<script setup>
import {computed,ref,onMounted,watch,defineProps} from 'vue'
import {Bar} from "@antv/g2plot";
import {getCellValue} from "@/utils";
import _ from 'lodash'
const props = defineProps({
  sheet:{
    default(){
      return [];
    }
  }
})
let ChartObj;
const TemplateData  = [
  {title:"西门处-长安立交桥",value:0},
  {title:"长安立交桥-光复路",value:0},
  {title:"站前路-百隆店护栏",value:0},
  {title:"安庆路-胜利桥",value:0},
];
const ChartData = computed(()=>{
  let data = [];
  TemplateData.forEach((item,key)=>{
    let success = _.cloneDeep(item);
    success.type ="已卖出"
    success.val = success.value = _.cloneDeep(item.success);

    data.push(success);
    let value = _.cloneDeep(item);
    value.type ="剩余"
    value.val = value.value = _.cloneDeep(item.value);
    data.push(value);
  })
   data.sort(function (x,y){
      return -(x.value - y.value);
    })
  return data;
})
const UpdateData = (sheet)=>{
  TemplateData.forEach((item,key)=>{
    let index = key+66;
    let success = getCellValue(sheet,`F${index}`);
    let total  =  getCellValue(sheet,`E${index}`);
    item.success = success
    item.value =   total - success;
  });
  if(ChartObj){
    ChartObj.destroy();
    createChart();
  }
}
watch(()=>props.sheet,(e)=>{
  UpdateData(e);
},{deep:true,immediate:true});
const createChart = ()=>{
  ChartObj = new Bar("gldgg",{
    data:ChartData.value,
    xField:"value",
    yField:"title",
    isPercent: true,
    isStack: true,
    seriesField: 'type',
    label: {
      position: 'middle',
      content: (item) => {
        if(item.value > 0){
          return `${item.type}:${item.val},占比:${parseFloat(item.value*100).toFixed(2)}%`
        }
      },
      style: {
        fill: '#fff',
      },
    },
  })
  ChartObj.render();
}
onMounted(createChart)

</script>

<style scoped>

</style>